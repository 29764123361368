<template>
  <div class="error-container">
    <figure class="error-logo">
      <img
        :src="brandingLogo"
      >
    </figure>
    <div>
      <h1 class="error-header">
        {{ $t('Views.NotFound.oops') }}
      </h1>
      <h5 class="error-message">
        {{ $t('Views.NotFound.not_found') }}
      </h5>
    </div>
    <router-link
      class="to-calender-icon"
      :to="'/calendar'"
    >
      <h5 class="x-big-medium">
        {{ $t('Views.NotFound.go_to') }}
      </h5>
      <icon
        icon="chevron-right"
        class="chevron settings_icon--right "
      />
    </router-link>
  </div>
</template>

<script>
import CompanyState from '@/singletons/company.state.singleton';

const DEFAULT_404_LOGO = require('@/assets/logos/404_logo.svg');

export default {
  computed: {
    default() { return DEFAULT_404_LOGO; },
    useBranding() { return false; },
    brandingLogo() { return this.useBranding ? CompanyState.branding?.logo : this.default; },
  },
};
</script>

<style lang="scss" scoped>
.error-container{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 500px;
  margin: 5% auto;
}
.error-logo{
  img{
    height: auto;
    width: 30rem;
  }
}
.error-message{
  font-size: 1.5rem;
}
.error-header{
 font-size: 4rem;
 font-weight: 800;
}
.to-calender-icon{
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  h5{
    font-size: 1rem;
    margin: auto 0;
  }
  svg{
    font-size: 1rem;
    margin: auto 0;
  }
}
</style>
